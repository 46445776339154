<template>
    <CModal :show.sync="popupModalProp.isShow">
		<template #header>
			<h5 class="modal-title">{{ popupModalProp.modalTitle }}</h5>
		</template>
        <!-- Input Form -->
		<div class="p-1">
            <label class="form-inline">Petunjuk</label>
            <div class="template-container">
                <CButton
                    @click="clickTemplate('ACC')"
                    color="danger"
                    variant="outline"
                    shape="pill"
                    size="sm"
                    type="button"
                    class="m-1">ACC</CButton>
                <CButton
                    @click="clickTemplate('Selesaikan')"
                    class="m-1"
                    variant="outline"
                    color="danger"
                    shape="pill"
                    size="sm"
                    type="button">Selesaikan</CButton>
                <CButton
                    @click="clickTemplate('Tindaklanjuti')"
                    class="m-1"
                    variant="outline"
                    color="danger"
                    shape="pill"
                    size="sm"
                    type="button">Tindaklanjuti</CButton>
                <CButton
                    @click="clickTemplate('Untuk diketahui')"
                    class="m-1"
                    variant="outline"
                    color="danger"
                    shape="pill"
                    size="sm"
                    type="button">Untuk diketahui</CButton>
                <CButton
                    @click="clickTemplate('Untuk dilaksanakan sesuai ketentuan')"
                    class="m-1"
                    variant="outline"
                    color="danger"
                    shape="pill"
                    size="sm"
                    type="button">Untuk dilaksanakan sesuai ketentuan</CButton>
            </div>
            <CInput
                v-model="dispositionForm.input.content"
                placeholder="Petunjuk"
                :disabled="editedIndexProp != -1"
                :is-valid="dispositionForm.validate.content"
                @change="validateForm('content')" />
            <div>
                <label class="typo__label">Penerima</label>
                <multiselect 
                    v-model="dispositionForm.input.selectedReceivers"
                    :options="dispositionForm.receiverOptions"
                    :multiple="true"
                    :allow-empty="false"
                    :close-on-select="false"
                    :is-valid="dispositionForm.validate.receivers"
                    label="label"
                    track-by="label"
                    @input="validateForm('receivers')"
                    placeholder="Pilih beberapa pengguna">
                </multiselect>
            </div>
            <div v-if="cShowReceiverCommands">
                <label>Perintah untuk:</label>
                <div v-for="(item, index) in dispositionForm.input.selectedReceivers" :key="item.userId">
                    <CInput
                        v-model="dispositionForm.input.selectedReceivers[index].command"
                        ref="receiver-command"
                        :label="item.label"
                        :placeholder="`Perintah untuk `+ item.label" />
                </div>
            </div>
            <div v-else>
                <CInput
                    ref="command-dummy"
                    label="Perintah untuk:"
                    description="Mohon untuk mengisi penerima memo terlebih dahulu"
                    disabled />
            </div>
        </div>
		<template #footer>
			<CButton @click="closePopupModal(false)" class="btn-sm">Batal</CButton>
            <CButton @click="saveItem" color="success" class="btn-sm text-white" :disabled="dispositionForm.submitBtn.disabled">
                <font-awesome-icon v-if="dispositionForm.submitBtn.loader" icon="spinner" spin/> {{ dispositionForm.submitBtn.text }}
            </CButton>
		</template>
	</CModal>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: [
        'popupModalProp',
        'editedIndexProp',
        'modelFormInputProp'
    ],
    watch: {
        modelFormInputProp: {
            handler: function (newValue, oldValue) {
                this.dispositionForm.input = Object.assign({}, this.dispositionForm.input, newValue)
                if (newValue.content && newValue.content != '')
                    this.dispositionForm.validate.content = true
            },
            deep: true,
            imediate: true
        },
        receiverOptionsProp: {
            handler: function (newValue, oldValue) {
                this.dispositionForm.receiverOptions = newValue
            },
            deep: true,
            imediate: true
        }
    },
    computed: {
        ...mapState({
			currentUser: 'auth'
		}),
        cShowReceiverCommands: function () {
            let selectedReceivers = this.dispositionForm.input.selectedReceivers
            return (selectedReceivers && selectedReceivers.length > 0) ? true : false
        }
    },
    mounted () {
        this.getReceiverOptions()
    },
    data () {
        return {
            curentUserPosition: null,
            dispositionForm: {
                editedIndex: this.editedIndexProp,
                receiverOptions: [],
                input: this.modelFormInputProp,
                defaultValue: {
                    // Memo
                    content: null,
                    docId: null,
                    // Disposition
                    // 
                    memoId: null,
                    receivers: [],
                    selectedReceivers: null,
                    commands: [],
                    memoRecordId: null
                },
                validate: {
                    content: false,
                    receivers: false,
                    commands: false
                },
                submitBtn: {
                    loader: false,
                    text: 'Simpan',
                    disabled: true
                }
            }
        }
    },
    methods: {
        clickTemplate(value) {
            this.dispositionForm.input.content = value
        },
        // APIs
        getReceiverOptions () {
            // Get same level users of same current user position
            this.$store.dispatch({
                type: 'position/getUserConfig',
                filter: {
                    position: this.currentUser.position,
                    isExact: true
                },
                pagination: {
                    limit: 100,
                    offset: 0
                },
                order: {
                    orderBy: 'position',
                    orderType: 'asc'
                }
            }).then( res => {
                let receiverOptions = []
                let currentPositionId = 0
				res.result.forEach( item => {
                    if (item.position == this.currentUser.position) {
                        currentPositionId = item.position_id
                    } else {
                        let rowData = {
                            label: item.position.trim(),
                            userId: item.user_id,
                            positionId: item.position_id,
                            position: item.position,
                            configPosition: item.config_position
                        }
                        receiverOptions.push(rowData)
                    }
				})
                
                // Get bellow level users of current user position
                this.$store.dispatch({
                    type: 'position/getUserPositionPid',
                    filter: { positionPid: currentPositionId }
                }).then( res => {
                    res.result.forEach((positionProp) => {
                        positionProp.user_list_id.forEach((item) => {
                            let rowData = {
                                label: positionProp.position.trim(),
                                userId: item.user_id,
                                positionId: positionProp.position_id,
                                position: positionProp.position,
                                configPosition: positionProp.config
                            }
                            receiverOptions.push(rowData)
                        })
                    })
                }).finally(() => {
                    this.dispositionForm.receiverOptions = receiverOptions
                })
            })
        },
        createMemo () {
            return new Promise ( (resolve, reject) => {
				this.$store.dispatch({
					type: 'disposition/storeMemo',
					formInput: this.dispositionForm.input
				}).then( res => {
					if (res.error)
						reject(res.error)
					
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
        },
        createDisposition () {
            return new Promise ( (resolve, reject) => {
				this.$store.dispatch({
					type: 'disposition/store',
					formInput: this.dispositionForm.input
				}).then( res => {
					if (res.error)
						reject(res.error)
					
					resolve(res.result)
				}).catch( err => {
					reject(err)
				})
			})
        },
        // Navigation & Actions
        async saveItem () {
            // Set submit button to loading the process
            this.dispositionForm.submitBtn = {
                loader: true,
                text: 'Memproses...'
            }

            // Modify selected receivers into single array of user id -
            let receivers = []
            let commands = []
            let selectedReceivers = this.dispositionForm.input.selectedReceivers
            for (let i = 0; i < selectedReceivers.length; i++) {
                let receiver = selectedReceivers[i].userId
                receivers.push(receiver)
                let command = selectedReceivers[i].command
                commands.push(command)
            }
            this.dispositionForm.input.receivers = receivers
            this.dispositionForm.input.commands = commands

            // Create memo when memo_id is null (Create new memo or create disposition from document)
            let memoId = this.dispositionForm.input.memoId
            if (!memoId || memoId == '') {
                let memoResult = await this.createMemo()
                    .catch((err) => {
						this.$toasted.error(err).goAway(3000)
						this.dispositionResult.submitBtn = {
							loader: false,
							text: 'Simpan'
						}
						return
                    })
                    
                this.dispositionForm.input.memoId = memoResult.memo_id
            }

            // Create diposition when memoId is provided
            let dispositionResult = await this.createDisposition()
                .catch((err) => {
                    this.$toasted.error(err).goAway(3000)
                    this.dispositionResult.submitBtn = {
                        loader: false,
                        text: 'Simpan'
                    }
                    return
                })
            this.$toasted.success('Sukses menambah data').goAway(3000)
            this.dispositionForm.submitBtn = {
                loader: false,
                text: 'Simpan'
            }
            this.closePopupModal(true)
        },
		validateForm (field, event) {
			if (field == 'content') {
				if (this.dispositionForm.input.content != '') {
                    this.dispositionForm.validate.content = true
				} else {
                    this.dispositionForm.validate.content = false
                    this.dispositionForm.submitBtn.disabled = true
					return false
				}
            }
			if (field == 'receivers') {
				if (this.dispositionForm.input.selectedReceivers.length > 0) {
					this.dispositionForm.validate.receivers = true
				} else {
					this.dispositionForm.validate.receivers = false
                    this.dispositionForm.submitBtn.disabled = true
					return false
				}
            }

			if (
                this.dispositionForm.validate.content &&
                this.dispositionForm.validate.receivers
            ) {
                this.dispositionForm.submitBtn.disabled = false
            } else {
                this.dispositionForm.submitBtn.disabled = true
            }
        },
		closePopupModal (refreshState) {
            // Reset receiver command dynamic inputs
            let selectedReceivers = this.dispositionForm.input.selectedReceivers
            if (selectedReceivers && selectedReceivers.length > 0) {
                for (let i = 0; i < selectedReceivers.length; i++) {
                    this.dispositionForm.input.selectedReceivers[i].command = null
                }
            }
            this.dispositionForm.input = Object.assign({}, this.dispositionForm.defaultValue)
            this.dispositionForm.validate = {
				content: null,
                receivers: null,
                commands: null
            }
            this.dispositionForm.submitBtn = {
                loader: false,
                text: 'Simpan',
                disabled: true
            }
            this.$emit('close-popup-modal', refreshState)
		}
    }
}
</script>

<style scoped lang="scss">
.template-container {
    color: #e55353;
}
</style>
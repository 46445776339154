<template>
<div class="doc-container">
    <CCard class="p-0">
        <CCardHeader>
            <div class="d-flex">
                <div class="flex-grow-1">
                    <h5 class="mb-0" style="line-height:2rem"><font-awesome-icon icon="folder" class="mr-10"/> Disposisi</h5>
                </div>
            </div>
        </CCardHeader>
        <CCardBody class="doc-action-container">
            <CTabs>
                <!-- Disposition Inbox -->
                <CTab active>
                    <template slot="title">
                        <font-awesome-icon icon="inbox" class="mr-10"/> Disposisi Masuk
                    </template>
                    <div class="p-0">
                        <v-data-table
                            :loading="loading"
                            loading-text="Memuat Data..."
                            :headers="inboxTable.headers"
                            :options.sync="inboxOptionsTable"
                            :server-items-length="inboxTable.totalData"
                            :multi-sort="false"
                            :items="inboxTable.dataList"
                            :footer-props="inboxTable.footerProps"
                            class="elevation-1">
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small class="mr-2 text-warning" @click="toDetail(item)">
                                    mdi-eye
                                </v-icon>
                                <v-icon small class="mr-2 text-primary" @click="showPopupModal(item, 'masuk')">
                                    mdi-share
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                <span>Disposisi Masuk Tidak Ditemukan</span>
                            </template>
                        </v-data-table>
                    </div>
                </CTab>
                <!-- Disposition Outbox -->
                <CTab>
                    <template slot="title">
                        <font-awesome-icon icon="paper-plane" class="mr-10"/> Disposisi Keluar
                    </template>
                    <div class="p-0">
                        <v-data-table
                            :loading="loading"
                            loading-text="Memuat Data..."
                            :headers="outboxTable.headers"
                            :options.sync="outboxOptionsTable"
                            :server-items-length="outboxTable.totalData"
                            :multi-sort="false"
                            :items="outboxTable.dataList"
                            :footer-props="outboxTable.footerProps"
                            class="elevation-1">
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small class="mr-2 text-warning" @click="toDetail(item)">
                                    mdi-eye
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                <span>Disposisi Keluar Tidak Ditemukan</span>
                            </template>
                        </v-data-table>
                    </div>
                </CTab>
            </CTabs>
        </CCardBody>
    </CCard>

    <!-- Popup Modal & Input Form -->
    <disposition-form
        v-bind:popup-modal-prop="popupModal"
        v-bind:edited-index-prop="modelForm.editedIndex"
        v-bind:model-form-input-prop="modelForm.input"
        v-bind:receiver-options-prop="modelForm.receiverOptions"
        v-on:close-popup-modal="closeDispositionForm">
    </disposition-form>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import DispositionForm from '@/components/document/DispositionForm.vue'
export default {
	names: 'Disposition',
	data () {
        const inboxTable = {
			headers: [
                { text: 'Dari', value: 'senderName', align: 'left', sortable: true },
                { text: 'Perihal', value: 'docTitle', align: 'left', sortable: true },
				{ text: 'Memo', value: 'content', align: 'left', sortable: false },
                { text: 'Instruksi', value: 'command', align: 'left', sortable: false },
                { text: 'Tanggal', value: 'createdAt', align: 'center', sortable: false },
				{ text: 'Aksi', value: 'actions', sortable: false, align: 'center' }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: []
        }
        const outboxTable = {
			headers: [
                { text: 'Kepada', value: 'receiverName', align: 'left', sortable: true },
                { text: 'Perihal', value: 'docTitle', align: 'left', sortable: true },
				{ text: 'Memo', value: 'content', align: 'left', sortable: false },
                { text: 'instruksi', value: 'command', align: 'left', sortable: false },
                { text: 'Tanggal', value: 'createdAt', align: 'center', sortable: false },
				{ text: 'Aksi', value: 'actions', sortable: false, align: 'center' }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: []
        }
		const popupModal = {
			isShow: false,
			modalTitle: 'Buat Disposisi'
		}
		const modelForm = {
            editedIndex: -1,
            receiverOptions: [],
			input: {
                // Memo
                content: null,
                docId: null,
                // Disposition
                memoId: null,
                receivers: [],
                selectedReceivers: null,
                commands: [],
                memoRecordId: null
			},
			defaultValue: {
				// Memo
                content: null,
                docId: null,
                // Disposition
                memoId: null,
                receivers: [],
                selectedReceivers: null,
                commands: [],
                memoRecordId: null
			}
		}

		return {
			loading: true,
            inboxTable,
			inboxOptionsTable: {},
            outboxTable,
            outboxOptionsTable: {},
			popupModal,
			modelForm
		}
    },
    watch: {
		inboxOptionsTable: {
			deep: true,
			handler () {
				this.getDataList('masuk')
					.then( data => {
						this.inboxTable.dataList = data.items
						this.inboxTable.totalData = data.total
					})
			}
		},
		outboxOptionsTable: {
			deep: true,
			handler () {
				this.getDataList('keluar')
					.then( data => {
						this.outboxTable.dataList = data.items
						this.outboxTable.totalData = data.total
					})
			}
        }
    },
	mounted () {
        this.getDataList('masuk')
			.then( data => {
				this.inboxTable.dataList = data.items
				this.inboxTable.totalData = data.total
			})
        this.getDataList('keluar')
			.then( data => {
				this.outboxTable.dataList = data.items
				this.outboxTable.totalData = data.total
			})
        this.getReceiverOptions()
	},
	methods: {
        // Apis
        getReceiverOptions () {
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 200,
						offset: 0
					},
					order: {
                        orderBy: 'fullname',
                        orderType: 'asc'
                    },
                    filter: {
                        isActive: 'true'
                    }
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
                    let rowData = {
						label: item.fullname.trim(),
						userId: item.user_id,
                        username: item.username,
                        fullname: item.fullname,
                        email: item.email,
                        rank: item.rank,
                        position: item.position,
                        command: null
                    }
					items.push(rowData)
				})
				
				this.modelForm.receiverOptions = items
			})
        },
        getDataList (typeDoc) {
            this.loading = true
			return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = (typeDoc == 'keluar') ? this.outboxOptionsTable : this.inboxOptionsTable
                
				this.$store.dispatch(
					{
						type: 'disposition/get',
						pagination: {
							limit: itemsPerPage,
							offset: (page-1)*itemsPerPage
						},
						filter: {
							type: typeDoc
						}
					}
				).then( res => {
					let items = []
					let total = 0
					res.result.forEach((item) => {
						let rowData = {
                            memoRecordId: item.memo_rec_id,
                            type: item.type,
                            prevRecId: item.previous_rec_id,
                            memoId: item.memo_id,
                            content: item.content,
                            docId: item.doc_id,
                            docTitle: item.doc_title,
                            command: item.command,
                            receiverId: item.receiver_id,
                            receiverName: item.receiver_name,
                            receiverPosition: item.receiver_position,
                            senderId: item.sender_id,
                            senderName: item.sender_name,
                            senderPosition: item.sender_position,
                            isRead: item.is_read,
							createdAt: this.$moment(item.created_at).format("YY/MM/DD")
						}
						items.push(rowData)
					})
					total = res.total_count
					
					setTimeout(() => {
						resolve({
							items,
							total
						})
					}, 1000)
				}).catch ( err => {
                    reject(err)
                })
			})
        },
        updateReadStatus (item) {
            this.$store.dispatch({
				type: 'disposition/update',
				formInput: {
                    dispositionId: item.memoRecordId,
                    isRead: true
                }
			}).then( res => {
                // When update status success, then update table data list is_read status
                if (res.error == null && res.result.is_read) {
                    let indexData = this.inboxTable.dataList.indexOf(item)
                    this.inboxTable.dataList[indexData].isRead = true
                    // console.log(this.inboxTable.dataList[indexData])
                }
			})
        },
        // Page Navigation and Function
        toDetail (item) {
            let id = item.memoRecordId
            let type = item.type
			this.$router.push('/doc_action/disposition/'+ type +'/detail/' + id)
        },
		showPopupModal (item, type) {
            if (item != null) {
                let modalTitle = (item.docId && item.docId != 0) ? 'Bagikan Surat' : 'Bagikan Memo'
                this.popupModal.modalTitle = modalTitle
                let tableDataList = (type == 'keluar') ? 
                    this.outboxTable.dataList : this.inboxTable.dataList
                this.modelForm.editedIndex = tableDataList.indexOf(item)
                this.modelForm.input.memoId = item.memoId
                this.modelForm.input.content = item.content
                this.modelForm.input.docId = item.docId
                this.modelForm.input.memoRecordId = item.memoRecordId
                // Update read status when dispostion is_read is false
                if (type == 'masuk' && !item.isRead)
                    this.updateReadStatus(item)
            } else {
                this.popupModal.modalTitle = 'Tambah Memo Baru'
                this.modelForm.editedIndex = -1
            }
            this.popupModal.isShow = true
		},
        // Component Event
        closeDispositionForm (refreshState) {
            this.popupModal.isShow = false
            let defaultValue = this.modelForm.defaultValue
            this.modelForm.input = Object.assign({}, defaultValue)
            if (refreshState) {
                this.getDataList('keluar')
                    .catch((err) => {
						this.$toasted.error(err).goAway(3000)
						this.dispositionResult.submitBtn = {
							loader: false,
							text: 'Simpan'
						}
						return
                    })
            }
        }
    },
    computed: {
    },
    components: {
        'disposition-form': DispositionForm
    }
}
</script>

<style scoped></style>